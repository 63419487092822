import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import StoryArticle from "../../components/successStories/StoryArticle";
import { SUCCESS_STORIES_LIST } from "../../constants/successStoriesList";

const AnnaUndPaul = () => {
    const key = "AnnaUndPaul";
    const { name, date, image, storyShort } = SUCCESS_STORIES_LIST[key];
    const dateParts = date.split(".");
    const isoDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0] + 1).toISOString().substring(0, 10);

    const { mobile, desktop } = image;

    return (
        <Layout>
            <MetaComponents title={`${name} | miracl`} description={storyShort} />
            <GreyHeader />
            <StoryArticle
                name={name}
                date={date}
                title={"Nach der Geburt von Isabella ..."}
                imageDesktop={desktop}
                imageMobile={mobile}
            >
                <p>
                    ... ihrem ersten Kind, war es für Paul und Anna [die Namen wurden geändert; Anm. d. Redaktion] klar:
                    Es brauchte eine Veränderung. Sie mussten raus aus der Stadt, in der es zwar Spielplätze,
                    Parkanlagen und hier und da auch ein paar Bäume gab, jedoch nichts, das sich mit den schier
                    unendlichen Wiesenflächen vergleichen ließe, die Paul aus seiner Kindheit kannte und auf denen auch
                    Isabella, wäre sie einmal groß genug dafür, nach Lust und Laune mit ihren Freund:innen herumtoben
                    könnte. – So stellte es sich das Paar jedenfalls vor und stieß mit dieser Idee auch bei Isabella auf
                    die wärmste Zustimmung (jedenfalls soweit sich das bei einem Kleinkind sagen lässt). Doch so gängig
                    der Traum vom Häuschen auf dem Lande für viele junge Familien ist, so schwer gestaltet sich in
                    zahlreichen Fällen auch seine Finanzierung. Paul, der zu dieser Zeit in einer Supermarktkette
                    arbeitete, und Anna, die gerade in Karenz war, um sich um die kleine Isabella zu kümmern, sahen sich
                    also vor die Herausforderung gestellt, einen Kredit zu finden, um ihren Traum vom Eigenheim
                    finanzierbar zu machen.
                </p>
                <p>
                    Schon nach kurzer Zeit und Gesprächen mit etlichen Kreditinstituten mussten sie allerdings am
                    eigenen Leib erfahren, was es für eine junge Familie heißt, heute ein Haus zu kaufen. Sämtliche
                    Banken, die sie kontaktierten, lehnten ihren Antrag mit demselben professionalisierten Bedauern ab.
                    Dabei benötigten Anna und Paul – oder “Panna”, wie die beiden von ihren gemeinsamen Freund:innen
                    liebevoll genannt werden – gerade einmal 50 000 €, um den Genossenschaftsbeitrag ihrer
                    Wunschimmobilie begleichen zu können. Selbst für eine nur mittelgroße Bank also kein nennenswertes
                    Risiko, würde man meinen.
                </p>
                <p>
                    Und das ist auch richtig. Andererseits – und genau an dieser Logik drohte Pauls und Annas Wohntraum
                    zu scheitern – auch kein lukratives Geschäft; bedenkt man, dass sich bei einer vergleichsweise
                    geringen Kreditmenge wie 50 000 € durch Zinseinnahmen nur relativ geringe Gewinne erzielen lassen.
                    Es hätte nicht viel gefehlt und das junge Paar hätte sich von diesem Rückmeldungen entmutigen lassen
                    – kein Wunder, fielen sie doch einstimmig negativ aus. Als sie ihrem Traum vom trauten Heim fast
                    schon aufgegeben hatten und beinahe nur noch halbherzig suchten, stießen sie schließlich auf miracl.
                    Dejan wurde zum persönlichen Berater von Paul und Anna und fand für sie ein Kreditinstitut, das
                    bereit war, auch einen derart niedrigen Betrag bereitzustellen. Miracl-Gründer David Savasci hatte
                    die Ehre, an dem Video-Call teilzunehmen, im Zuge dessen Dejan Paul und Anna darüber informieren
                    durfte, dass ihr Antrag angenommen wurde. Nach all den Absagen sei sich das Paar sicher gewesen,
                    dass auch dieser Anruf nur eine weitere Enttäuschung zum Gegenstand habe, erinnert sich David : “Als
                    Dejan ihnen sagte, dass ihr Traum in Erfüllung gehen würde, sah es so aus, als wäre ihr Bildschirm
                    eingefroren. Sie waren sprachlos.” Sofort schrieb er dem ganzen miracl-Team und berichtet von diesem
                    bemerkenswerten Moment – um Geschichte(n) wie diese zu schreiben, wurde miracl überhaupt erst
                    gegründet.
                </p>
            </StoryArticle>
            <BreadcrumbList page={key}></BreadcrumbList>
            <ArticleStructuredData
                page={key}
                heading={name}
                description={storyShort}
                datePublished={isoDate}
                dateModified={isoDate}
            />
        </Layout>
    );
};

export default AnnaUndPaul;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
